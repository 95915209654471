import { createApp } from "vue";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import { createWebHistory } from "vue-router";
import { createAppRouter } from "./router/router.js";
import { QueryClient, hydrate, VUE_QUERY_CLIENT } from "vue-query";
import createStore from './store'
import App from "./App.vue";
import instances from "./Axios Instances"
import axios from "axios";
// import * as Keycloak from "keycloak-js";

const start = async (keycloak) => {
  console.log("Client entry")
  const app = createApp(App);

  const router = createAppRouter({ history: createWebHistory() });
  app.use(router);

  const store = createStore()

  // console.log(store)
  store.subscribe((mutation, state) => {
    console.log("MUTATION")
    // Store the state object as a JSON string
    sessionStorage.setItem('store', JSON.stringify(state));
  });  
  app.use(instances)
  app.use(store)
  // console.log(keycloak)
  if (keycloak) {
    // console.log(app)
    console.log("Keycloak is true")
    app.config.globalProperties.$keycloak = keycloak
    //set dafault headers
    app.config.globalProperties.$payments.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
    app.config.globalProperties.$paymentsProfile.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
  }

  app.config.globalProperties.$formatPay = (amount, currency) => {
    let price = (amount / 100).toFixed(2);
    let numberFormat = new Intl.NumberFormat(["en-US"], {
      style: "currency",
      currency: currency,
      currencyDisplay: "symbol",
    });
    return numberFormat.format(price);
  }

  app.config.globalProperties.$formatCurrency = (amount,currency,exchange_rate)=>{
    try{
      console.log("amount to set : ",amount," Currency : ",currency," exchange rate : ",exchange_rate)
      let convertedAmount = amount * exchange_rate;
      // let price = (parseInt(convertedAmount)).toFixed(2);
      let numberFormat = new Intl.NumberFormat(["en-US"], {
        style: "currency",
        currency: currency,
        currencyDisplay: "symbol",
        maximumFractionDigits: 2
      });
      return numberFormat.format(convertedAmount);
    }
    catch(err){
      console.log(err)
      return null
    }
  }

  app.config.globalProperties.$formatAmount = (amount,exchange_rate)=>{
    try{
      console.log("amount to set : ",amount," exchange rate : ",exchange_rate)
      let convertedAmount = amount * exchange_rate;
      // let price = (parseInt(convertedAmount)).toFixed(2);
      let numberFormat = new Intl.NumberFormat(["en-US"], {
        maximumFractionDigits: 2
      });
      return numberFormat.format(convertedAmount);
    }
    catch(err){
      console.log(err)
      return null
    }
  }

  app.config.globalProperties.$getSettings = ()=>{
    console.log("getting settings")
    return axios.get("https://api.cubeitdone.com/api/settings",{ headers: {
                  Authorization: 'bearer ' + keycloak.token, //the token is a variable which holds the token
          }}).then((data)=>{
            return data.data.wallpaper_details.urls.full
          }).catch(()=>{
            return null
          })
  }

  const metaManager = createMetaManager();
  app.use(metaManager);
  app.use(metaPlugin);

  const queryState = JSON.parse(
    document.querySelector("[data-query-state]").textContent
  );
  const client = new QueryClient();
  hydrate(client, queryState);
  client.mount();
  app.provide(VUE_QUERY_CLIENT, client);

  if (window.__INITIAL_STATE__.$customer_info) {
    // We initialize the store state with the data injected from the server
    store.replaceState(window.__INITIAL_STATE__)
  }
  else{
    const state = JSON.parse(sessionStorage.getItem('store'));
    if (state) {
      state.$dataFrom = "sessionStorage"
      store.replaceState(state);
    }
  }

  await router.isReady();

  app.mount("#app");
};

let initOptions = {
  url: 'https://login.cubeitdone.com/auth',
  realm: "cubeItDone",
  clientId: "cu-circuits",
  onLoad: "check-sso",
  checkLoginIframe: true
};

let keycloak = Keycloak(initOptions);

  keycloak.init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (auth) {
      start(keycloak);
    } else {
      console.error("Authentication Failed");
      window.location.assign(process.env.APPLICATION_RETURN_TO_ON_FAILURE)
    }
  })
.catch(()=>{
  console.error("Authenticated Failed");
})
