<template>
    <div class="address-card" :style="setBackground(address.address_img)">
        <div class="address-content">
            <h1 class="address-name">{{address.address_name}} <i v-if="!$store.state.$isBillingSame">- {{type}}</i></h1>
            <div class="address-details">
                <h2 class="address-line-1 address-line-2 address-city address-pin address-state">{{address.line_1}}, {{address.line_2}} {{address.city}} {{address.pincode}}, {{address.address_state}}</h2>
                <h2 class="address-country">{{address.country}}</h2>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    props:{
        address:{
            type:Object,
            // required:true
        },
        type:{
            type:String,
            required:true
        }
    },
    data(){
        return{
            Addresses:
                {
                name:"Cu Circuits",
                number:"",
                line1:"Sadullah Street",
                line2:"T.nagar",
                city:"Chennai",
                pin:"600017",
                state:"Tamil Nadu",
                country:"India",
                img:null
            }
            
        }
    },
    methods:{
        setBackground(image){
          return {
            background:`linear-gradient(0deg, transparent, white), url(${image})`,
            backgroundRepeat:'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '120%'
            }
        },
    }
}
</script>
<style scoped>
.address-card h1{
    text-align: left;
    /* height:10%; */
    width:100%;
    font-size: 1rem;
    /* color:black; */
}
.address-card h2{
    margin:0;
    font-size: 0.9rem;
    text-align: left;
    /* height:10%; */

    width:100%;
    /* height:100%; */
    /* background-color: aqua; */
    /* color:black; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.address-card{
    display:flex;
    align-items: center;
    justify-content: center;
    max-height:120px;
    width:100%;
    border-radius: 5px;
    background-repeat: no-repeat;
    color:black;
    background-position: center;
    background-size: 120%;
    /* box-shadow: 0 0 20px -10px; */
    user-select: none;
}
.address-content{
    height:90%;
    width:95%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}
.address-name{
    letter-spacing: 1px;
    width:90%;
    margin:10px 0px 10px 0px;
}
.address-details{
    margin-bottom:10px;
}
.address-line-1{
    font-weight:lighter;
    width:90%;
    /* height:25%;    */
}
.address-line-1, .address-line-2, .address-city, .address-pin, .address-state{
    font-weight:300;
}
</style>