<template>
  <router-view v-slot="{ Component }">
    <transition  name="page" mode="out-in">
        <!-- <div class="loading" v-if="false">
          <h1>LOADING....</h1>
        </div> -->
      <component :is="Component" />
    </transition>
  </router-view>
<!-- <transition name="page" appear mode="out-in">
  <router-view></router-view>
</transition> -->
</template>
<script>
export default{
  data(){
    return{
      loading:true
    }
  },
  mounted(){
    // document.getElementById("initialState").remove()
    this.loadImage()
  },
  methods:{
    async loadImage(){
      const img = new Image();
      img.onload = () => {
        document.getElementById("app").style.backgroundImage = `url(${img.src})`
        setTimeout(() => {
          this.loading = false
        }, 1000);
        };
      const src = await this.$getSettings()
      img.src = src
    }
  }
}
</script>
<style>
* {
  box-sizing: border-box;
}
#app {
  user-select: none;
  /* background:linear-gradient(220deg,#b9b9b9,#fff); */
    background-color: whitesmoke;
    background-size: cover;
    background-repeat: no-repeat;
    height:100vh;
    width:100vw;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: neue-haas-unica,sans-serif!important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199); 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adadad; 
}

.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s ease;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
@keyframes blur-in {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(50px);
  }
}
</style>
