<template>
<transition name="page" appear>
  <div v-if="!$store.state.$cart.length" class="loading">
    <h1>LOADING....</h1>
  </div>
  <div class="main" v-else>
    <div class="container-1">
      <Header></Header>
      <div class="customer-info-container">
        <div class="customer-info">
          <h1 class="name">{{$store.state.$customer_info.name}}</h1>
          <p class="email">{{$store.state.$customer_info.email}}</p>
          <p class="phone">{{$store.state.$customer_info.phone_number}}</p>
        </div>
      </div>
      <div class="order-info">
        <div class="address-info billing-address" v-if="!$store.state.$isBillingSame">
          <AddressCardSmall :type="`Billing Address`" :address="$store.state.$billing_address"></AddressCardSmall>
        </div>
        <div class="address-info shipping-address">
          <AddressCardSmall :type="`Shipping Address`" :address="$store.state.$shipping_address"></AddressCardSmall>
        </div>
        <div class="cost-breakdown">
          <!-- <div class="order-items"> -->
            <item v-for="(item,index) in cart" :key="index" :item="item"></item>

          <!-- </div> -->
        </div>
      </div>
      <div class="shipping-methods-container">
        <ShippingMethod :method="$store.state.$selectedShippingMethod"></ShippingMethod>
      </div>
      <div class="cost-info">
        <div class="sub-total">
          <h5>Sub Total</h5>
          <h4>{{ subTotal}}</h4>
        </div>
        <div class="total"> 
          <h2>Total</h2>
          <h3>{{ total}}</h3>
        </div>
      </div>
    </div>
    <div class="container-2">
      <!-- <Payment v-bind:amount="totaltoPay" v-bind:currency="currency" v-bind:additionalData="additionalData"></Payment> -->
      <Payment v-bind:amount="totaltoPay" v-bind:currency="currency" v-bind:additionalData="additionalData"></Payment>
    </div>
  </div>
</transition>
</template>
<script>
import AddressCardSmall from '../components/addressCardSmall.vue'
import Payment from "../components/Payments/Payment.vue"
import Item from "../components/Item.vue"
import ShippingMethod from "../components/ShippingMethod.vue"
import Header from "../components/Header.vue"
export default {
      // return the Promise from the action
    // so that the component waits before rendering

  components:{
    Header,
    Payment,
    AddressCardSmall,
    Item,
    ShippingMethod
  },
  computed:{
    cart(){
      return this.$store.state.$cart
    },
    shippingAddress(){
      return this.$store.state.$shipping_address
    },
    billingAddress(){
      return this.$store.state.$billing_address
    },
    shipping(){
      return this.$store.state.$shipping
    },
    discounts(){
      return this.$store.state.$discounts
    },
    currency(){
      return this.$store.state.$order_info.currency
    },
    subTotal(){
      return this.format(this.$store.state.$cart_sub_total)
    },
    total(){
      const amount = parseFloat(this.$store.state.$cart_total) + parseFloat(this.$store.state.$selectedShippingMethod?.cost)
      return this.format(amount)
    },
    totaltoPay(){
      const amount = parseFloat(this.$store.state.$cart_total) + parseFloat(this.$store.state.$selectedShippingMethod?.cost)
      return this.$formatAmount(amount,this.$store.state.$order_info.exchange_rate)
    },
    additionalData(){
      return {
        customer_info:this.$store.state.$customer_info,
        shipping_address:this.$store.state.$shipping_address,
        billing_address:this.$store.state.$billing_address,
        billing_same:this.$store.state.$isBillingSame,
        order_id:this.$store.state.$order_id,
        shipping_method:this.$store.state.$selectedShippingMethod,
        analyzed:this.$store.state.$analyzed,
        created_at:this.$store.state.$created_at,
        exchange_rate:this.$store.state.$order_info.exchange_rate
      }
    }
  },
  data(){
    return{
    }
  },
  beforeCreate(){
    // this.check()
  },
  created(){
    this.check()
  },
  beforeMount(){
    // this.check()
  },
  mounted(){
    // this.check()
    console.log(this.shippingAddress)
    console.log(this.cart)
    console.log(document)
  },
  methods:{
    check(){
        console.log("SHIPPING METHOD",this.$store.state.$selectedShippingMethod,this.$store.state.$order_id)
        const methodData = this.$store.state.$selectedShippingMethod
        if(!methodData){
          this.goBack()
        }
    },
    goBack(){
      this.$router.back()
    },
    format(amount){
      return this.$formatCurrency(amount,this.currency,this.$store.state.$order_info.exchange_rate)
    }
  }

}
</script>
<style scoped>
.main{
  /* background-color: black; */
  width:100vw;
  height:100vh;
  display:flex;
  justify-content: center;
  align-items: center;
}
.container-2{
  width:60%;
  height:100%;
  /* background-color: rgba(246, 255, 127, 0.383); */
  display:flex;
  justify-content: center;
  align-items: center;
}

.customer-info-container{
  width:100%;
  /* height:100px; */
  padding:50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-info{
  /* background-color: aqua; */
  width:90%;
  height: 90%;
  display:grid;
  grid-template-areas: "name name" "email phone";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /* grid-gap: 10px; */
}

.customer-info .name{
  grid-area:name;
  text-align: left;
  margin:0;
  /* color: rgb(155, 155, 155); */
  color: rgb(215, 215, 215);
}
.customer-info .email{
  grid-area:email;
  color:white;
  text-align: left;
}
.customer-info .phone{
  grid-area:phone;
  color:white;
  text-align: left;
}

.order-info{
  width:100%;
  display:flex;
  flex-direction: column;
  flex:1;
  overflow: hidden;
  align-items: center;
}

.address-info{
  width:90%
}

.container-1 .shipping-methods-container{
  padding:0;
  user-select: none;
  margin-top:auto;
}

.container-1 .shipping-methods-container .shipping-methods{
  width:100%;
  cursor:default;
  border-radius:0px;
}
.order-info > *{
  margin:5px 0;
}

.cost-breakdown{
  overflow-y:auto;
  /* background-color: white; */
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:5px;
  border-radius: 5px;
  width:100%;
}
.order-items{
  height:100%;

}

.cost-info{
  background-color: rgba(255, 255, 255, 0.938);
}
.cost-info > *{
  display:flex;
  color:black;
  width:50%;
  text-align: center;
}


</style>