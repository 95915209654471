<template>
    <div class="payment">
        <form id="payment-form" class="saved-payments"  v-on:submit="makePayment($event)">
            <div class="payments-list">
                <div v-for="card,index in cardList" v-bind:key="index" class="card">
                    <input type="radio" name="payment-method" :id="`method-${index}`" class="card-radio" :value="card" v-model="picked">
                    <label :for="`method-${index}`" class="card-details">
                        <div class="card-data">
                            <p class="holder-name">{{card.metadata.name}}</p>
                            <img v-bind:src="getCardImg(card.card.brand)" class="card-brand"/>
                            <p class="card-last4">#{{card.card.last4}}</p>
                            <p class="card-exp">{{card.card.exp_month}}/{{card.card.exp_year}}</p>
                            <div :id="card.id" class="card-cvc"></div>
                        </div>
                    </label>
                </div>
            </div>
            <button id="submit" v-bind:disabled="!method || !complete">
              <div class="spinner hidden" id="spinner"></div>
              <span id="button-text">Pay now</span>
            </button>
            <div id="payment-message" class="hidden"></div>
        </form>
    </div>
</template>

<script>
const cardImages = {
  "visa":require("../../assets/card-icons/Visa.png"),
  "mastercard":require("../../assets/card-icons/MasterCard.png"),
  "amex":require("../../assets/card-icons/American Express.png"),
  "american express":require("../../assets/card-icons/American Express.png"),
  "discover":require("../../assets/card-icons/Discover.png"),
  "jcb":require("../../assets/card-icons/JCB.png"),
  "diners":require("../../assets/card-icons/Diners Club.png"),
  "unionpay":require("../../assets/card-icons/UnionPay.png"),

  "unknown":null
}
export default {
    props:{
        cardList:{
            type:Array,
            required:true
        },
        secret: {
            type: String,
            required: true
        },
        pi_id:{
          type:String,
          required:true
        },
        amount: {
          type: String,
          required: true
        },
        currency:{
          type:String,
          required:true
        },
        additionalData:{
          type:Object,
          required:true
        },
        stripe:{
            type:Object,
            required:true
        }
    },
    data(){
        return{
            method:null,
            parentView:this.$parent.$parent.$parent,
            paymentElement:null,
            complete:false
        }
    },
    mounted(){
        if(!this.method) document.getElementById("submit").disabled = true

    },
    beforeUnmount(){
        if(this.paymentElement){
            this.paymentElement.destroy()
            this.paymentElement = null
        }
    },
    computed:{
        picked:{
            get(){
                return this.method
            },
            set(value){
                console.log(value)
                this.method = value
                // this.method = value.card
                if(!this.paymentElement){
                  this.createElement(value)
                }
                else{
                  this.complete = false
                  console.log(this.paymentElement)
                  this.paymentElement.clear()
                  this.paymentElement.unmount()
                  this.paymentElement.mount(`#${value.id}`)
                }
                
            }
        }
      },
    methods:{
        getCardImg(brand){
          // console.log(brand)
          // console.log(cardImages[brand.toLowerCase()])
            return cardImages[brand.toLowerCase()].default || cardImages["unknown"]
        },
        createElement(value){
          const elements = this.stripe.elements({clientSecret:this.secret });
          const paymentElement = elements.create("cardCvc",{
  style: {
    base: {
      textAlign:'center',
    }
    }
}); 
          paymentElement.on('change',(event)=>{
            console.log("Change",event)
            this.complete = event.complete
          })
          paymentElement.on("ready", () => {
            this.elements = elements;
            this.parentView.setLoading(false)
          });
          // console.log(document.querySelectorAll('.1671084624'))
          paymentElement.mount(`#${value.id}`);
          this.paymentElement = paymentElement
        },
        getShippingDetails(){
            const details = this.additionalData
            return {
              name: details.shipping_address.address_name,
              phone: details.customer_info.phone_number,
              address: {
                line1: details.shipping_address.line_1,
                line2: details.shipping_address.line_2,
                city: details.shipping_address.city,
                state: details.shipping_address.address_state,
                postal_code: details.shipping_address.pincode,
                country: details.shipping_address.country_code,
              },
            }
        },
        getBillingDetails(){
            const details = this.additionalData
            console.log(details.billing_same)
            if(details.billing_same){
              return {
                name: details.shipping_address.address_name,
                email: details.customer_info.email,
                phone: details.customer_info.phone_number,
                address: {
                  line1: details.shipping_address.line_1,
                  line2: details.shipping_address.line_2,
                  city: details.shipping_address.city,
                  state: details.shipping_address.address_state,
                  postal_code: details.shipping_address.pincode,
                  country: details.shipping_address.country_code,
                },
              }
            }
            else{
              return {
                name: details.billing_address.address_name,
                email: details.customer_info.email,
                phone: details.customer_info.phone_number,
                address: {
                  line1: details.billing_address.line_1,
                  line2: details.billing_address.line_2,
                  city: details.billing_address.city,
                  state: details.billing_address.address_state,
                  postal_code: details.billing_address.pincode,
                  country: details.billing_address.country_code,
                },
              }
            }
        },
        async makePayment(event){
          if(!this.picked) return;
          this.parentView.setLoading(true)
          event.preventDefault();
          const pick = JSON.parse(JSON.stringify(this.picked))  
          pick.billing_details = this.getBillingDetails()
          console.log(pick)
          const {error} = await  this.stripe.confirmCardPayment(this.secret,{
            //`Elements` instance that was used to create the Payment Element
            payment_method:pick.id,
            payment_method_options:{
              card:{
                cvc:this.paymentElement
              }
            },
            // shipping: this.getShippingDetails(),
            return_url: `${process.env.VUE_APP_BASE_URL}/status`
            // confirmParams: {
            //   return_url: 'http://localhost:8081/status',
            //   payment_method_data:{
            //     billing_details:{
            //       ...this.getBillingDetails()
            //     }
            //   }
            // },
          });
          if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            const messageContainer = document.querySelector('#payment-message');
            messageContainer.classList.remove('hidden');
            console.log(error)
            messageContainer.textContent = error.message;
            this.parentView.setLoading(false)
          } else {
            console.log("Processed")
            window.location.assign(`${process.env.VUE_APP_BASE_URL}/status?payment_intent_client_secret=${this.secret}`)
            // payment_intent_client_secret
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }

        },
        // async makePayment(event){
        //   if(!this.picked) return;
        //   this.parentView.setLoading(true)
        //   event.preventDefault();
        //   const pick = JSON.parse(JSON.stringify(this.picked))  
        //   pick.billing_details = this.getBillingDetails()
        //   console.log("confirm","paymentMethod ID",pick.id,"paymentIntent ID",this.pi_id)
        //   this.$payments.post("/confirm",{
        //     payment_method_id:pick.id,
        //     amount: this.amount,
        //     currency: this.currency,
        //     additionalData: this.additionalData
        //   }).then((res)=>{
        //     console.log(res)
        //     this.parentView.setLoading(false)
        //     console.log("Confirm Success")
        //   }).catch((err)=>{
        //     console.log(err)
        //     this.parentView.setLoading(false)
        //   })

        // },
        // async makePayment(event){
        //   if(!this.picked) return;
        //   this.parentView.setLoading(true)
        //   event.preventDefault();
        //   const pick = JSON.parse(JSON.stringify(this.picked))  
        //   pick.billing_details = this.getBillingDetails()
        //   console.log(pick)
        //   const {error} = await  this.stripe.confirmCardPayment(this.secret,{
        //     //`Elements` instance that was used to create the Payment Element
        //     payment_method:pick.id,
        //     payment_method_options:{
        //       card:{
        //         cvc:this.paymentElement
        //       }
        //     },
        //     // shipping: this.getShippingDetails(),
        //     return_url: 'http://localhost:8081/status'
        //     // confirmParams: {
        //     //   return_url: 'http://localhost:8081/status',
        //     //   payment_method_data:{
        //     //     billing_details:{
        //     //       ...this.getBillingDetails()
        //     //     }
        //     //   }
        //     // },
        //   });
        //   if (error) {
        //     // This point will only be reached if there is an immediate error when
        //     // confirming the payment. Show error to your customer (for example, payment
        //     // details incomplete)
        //     const messageContainer = document.querySelector('#payment-message');
        //     messageContainer.classList.remove('hidden');
        //     console.log(error)
        //     messageContainer.textContent = error.message;
        //     this.parentView.setLoading(false)
        //   } else {
        //     console.log("Processed")
        //     window.location.assign("http://localhost:8081/status?payment_intent_client_secret="+this.secret)
        //     // payment_intent_client_secret
        //     // Your customer will be redirected to your `return_url`. For some payment
        //     // methods like iDEAL, your customer will be redirected to an intermediate
        //     // site first to authorize the payment, then redirected to the `return_url`.
        //   }

        // },
    }
}
</script>

<style>
.payments-list{
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    flex:1;
    max-height: 246.45px;
    overflow-y:auto;
    margin-bottom:24px;
}

.select-method{
    box-shadow: unset;
    transition:none;
    background-color: transparent;
    border-bottom:5px solid black;
    color:black;
    border-radius:0px;
    text-align: left;
}

.select-method[data-selected="false"]{
    background-color: transparent;
    border-bottom:0px solid black;
    color:black;
    opacity:0.5;
}

.card{
    background-color: #d9d9d9b4;
    height:69px;
    width:90%;
    display:flex;
    border-radius:5px;
}
.card-details{
    /* background-color: aqua; */
    width:100%;
    color:black;
}
.card-data{
    display:grid;
    align-items: center;
    gap:5px;
    height:100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows:1fr 1fr;
    grid-template-areas:
    "name name name brand"
    "last4 exp cvc brand";
}

.card-data p{
    padding:5px;
    margin:0;
    text-align:left;
    /* background-color: bisque; */
}

.holder-name{
    grid-area:name;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.card-brand{
    grid-area:brand;
    height:70%;
    justify-self: center;
}
.card-last4{
    grid-area:last4;
}
.card-exp{
    grid-area:exp;
}
.card-cvc{
  grid-area:cvc;

}

.card-data .StripeElement{
  height:80%;
  width:90%;
  display:flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  background-color: rgba(253, 253, 253, 0.739);
  border-radius:5px;
  border:2px solid black;
}

.card-data .StripeElement .__PrivateStripeElement{
  width:100%;
}

input[type="radio" i]{
    position: relative;
    opacity:1;
    margin:0;
    border-radius:5px 0px 0px 5px;
}

input[type="radio" i]::after{
    content:"";
    opacity:1;
    height:100%;
    width:100%;
    position: absolute;
    background-color: #B4B4B4;
    border-radius:5px 0px 0px 5px;
}

input[type="radio" i]:checked::after{
    background-color: black;
}
</style>
<style>
.InputElement{
  border-radius:5px;
}
</style>