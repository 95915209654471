<template>
<div class="order-details-container">
    <h1>Order Details</h1>
    <div class="order-details">
        <div class="order-info">
            <Item v-for="(item,index) in cart" :key="index" :item="item"></Item>
        </div>
    </div>

    <div class="billing-address">
      <div class="billing-address-content">
        <label class="toggle" for="isSame">
          <input type="checkbox" class="toggle__input" id="isSame" v-model="$store.state.$isBillingSame" />
          <h5>Shipping Address is the same as Billing Address</h5>
        </label>
        <transition name="fade" mode="in-out">
          <div v-if="!$store.state.$isBillingSame" class="billing-address-details">
            <AddressCard v-model:address="billingAddress" ref="billing"></AddressCard>
          </div>
        </transition>
      </div>
    </div>
    <div class="cost-info">
        <div class="sub-total">
          <h3>Sub Total</h3>
          <h2>{{format($store.state.$cart_total)}}</h2>
        </div>
    </div>
</div>
</template>

<script>
import AddressCard from './addressCard.vue'
import Item from './Item.vue'
export default {
  components: { Item, AddressCard},
  computed: {
    cart() {
      return this.$store.state.$cart
    },
    address() {
      return this.$store.state.$address
    },
    shipping() {
      return this.$store.state.$shipping
    },
    discounts() {
      return this.$store.state.$discounts
    }
  },
  data(){
    return{
      billingAddress:this.$store.state.$billing_address
    }
  },
  watch:{
    billingAddress:{
      handler(){
        console.log("BILLINGCHANGE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        this.$store.commit('setBillingAddress',this.billingAddress)
      },
      deep:true
    }
  },
  methods:{
    format(amount){
      return this.$formatCurrency(amount,this.$store.state.$order_info.currency,this.$store.state.$order_info.exchange_rate)
    }
  }
}
</script>

<style scoped>
.order-details-container{
    height:calc(100% - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.order-details-container h1{
    width:90%;
    text-align: left;
    font-size: 1.5rem;
    color: rgb(215, 215, 215);
}
.logo-container{
  height:100px;
  width:95%;
  padding-top:10px;
  display:flex;
  align-items: center;
  padding:10px 0px;
}
.logo-container img{
  width:auto;
  height:100%;
  margin-left:auto;
}
.logo-container button{
    height:50px;
    width:200px;
    outline:none;
    border:none;
    background-color: transparent;
}
.order-details{
    flex:1;
    width:100%;
    overflow-y: scroll;
}
.order-info{
    /* flex:1; */
    height:100%;
    width:100%;
    display:grid;
    grid-auto-rows:110px;
    gap:5px;
    justify-items: center;
}

.billing-address{
  width:100%;
  max-height:300px;
  height:max-content;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.billing-address-content{
  width:95%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(164, 164, 154, 0.558);

  /* background-color: aqua; */
  margin:10px;
  border-radius:10px;
}
.billing-address .toggle{
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  height:50px;
}
.billing-address .toggle h5{
  width:auto;
  text-align: left;
  margin:0;
  margin-right:auto;
  font-size:1rem;
}
#isSame{
  width:24px;
  height:24px;
  background-color: black;
  accent-color: black;
  margin-right:50px;
  margin-left:50px;
}
.billing-address-details{
  width:100%;
  height:100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
  max-height:300px;
  overflow: hidden;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height:0px;
  margin:0px;
}
</style>

