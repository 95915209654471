<template>
  <div class="Payment" v-if="!error">
    <form id="payment-form" v-on:submit="makePayment($event)">
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <button id="submit" :disabled="!complete">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">Pay now</span>
      </button>
      <div id="payment-message" class="hidden"></div>
    </form>
  </div>
  <div class="Payment" v-else>
    <h1>There was an error, please try again later</h1>
  </div>
</template>
<script>
export default {
  props: {
    secret: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    currency:{
      type:String,
      required:true
    },
    additionalData:{
      type:Object,
      required:true
    },
    stripe:{
        type:Object,
        required:true
    }
  },

  data() {
    return {
      error:false,
      elements:null,
      complete:false,
      parentView:this.$parent.$parent.$parent
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    getBillingDetails(){
      const details = this.additionalData
      console.log(details.billing_same)
      if(details.billing_same){
        return {
          name: details.shipping_address.address_name,
          email: details.customer_info.email,
          phone: details.customer_info.phone_number,
          address: {
            line1: details.shipping_address.line_1,
            line2: details.shipping_address.line_2,
            city: details.shipping_address.city,
            state: details.shipping_address.address_state,
            postal_code: details.shipping_address.pincode,
            country: details.shipping_address.country_code,
          },
        }
      }
      else{
        return {
          name: details.billing_address.address_name,
          email: details.customer_info.email,
          phone: details.customer_info.phone_number,
          address: {
            line1: details.billing_address.line_1,
            line2: details.billing_address.line_2 ? details.billing_address.line_2 : "",
            // line2: "",
            city: details.billing_address.city,
            state: details.billing_address.address_state,
            postal_code: details.billing_address.pincode,
            country: details.billing_address.country_code
          },
        }
      }
    },
    async makePayment(event){
      event.preventDefault();
      if(!this.elements) return;

      const {error} = await  this.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements:this.elements,
        confirmParams: {
          return_url: `${process.env.VUE_APP_BASE_URL}/status`,
          payment_method_data:{
            billing_details:{
              ...this.getBillingDetails()
            }
          }
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#payment-message');
        messageContainer.classList.remove('hidden');
        console.log(error)
        messageContainer.textContent = error.message;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

    },
    async initialize() {
      try{
        this.parentView.setLoading(true)
        this.parentView.updatePayText("Pay "+this.$formatPay(this.amount,this.currency))
        const appearance = {
          theme: "flat",
        };
        const elements = this.stripe.elements({ appearance, clientSecret:this.secret });
        console.log(this.stripe);


        const paymentElement = elements.create("payment",{fields:{billingDetails:"never"}}); 
        paymentElement.on('change',(event)=>{
          console.log("Change",event)
          this.complete = event.complete
        })
        paymentElement.on("ready", () => {
          this.elements = elements;
          this.parentView.setLoading(false)
        });
        paymentElement.mount("#payment-element");

      }
      catch(e){
        console.log(e)
        this.error = true
      }
    },
  },
};
</script>
<style>
.Payment{
  width:100%;
  height:auto;
  display:flex;
  justify-content:center;
  align-items:center;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #000000;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}
</style>