<template>
<div class="header-wrapper">
    <div>
        {{$store.state.$order_info.currency}}
        {{$store.state.$order_info.exchange_rate}}
        <br>
        Analyzed :
        {{ $store.state.$analyzed }}
        Created At :
        {{ $store.state.$created_at }}
    </div>
    <div class="logo-container">
        <img src="../assets/logo-light.png" alt="">{{$store.state.$dataFrom}}
    </div>
</div>

</template>
<style scoped>
.header-wrapper{
    display:flex;
    width:100%;
    height:100px;

}
.logo-container{
  height:100%;
  width:200px;
  margin-left:auto;
  padding-top:10px;
}
.logo-container img{
  width:auto;
  height:100%;
}
</style>