<template>
  <div class="address-card" id="billing-address" :style="setBackground(Addresses.address_img)">
    <div class="address-content">
      <input type="text" v-model="Addresses.name" autocomplete="name" placeholder="Name" class="address-name"/>
      <input type="text" v-model="Addresses.line_1" autocomplete="address-line1" x-autocompletetype="”address”" placeholder="Line 1" class="address-line-1"/>
      <input type="text" v-model="Addresses.line_2" autocomplete="address-line2" x-autocompletetype="”address”" placeholder="Line 2" class="address-line-2"/>
      <input type="text" v-model="Addresses.city" autocomplete="address-level2" placeholder="City" class="address-city"/>
      <input type="text" v-model="Addresses.pincode" autocomplete="postal-code" placeholder="Pin Code" class="address-pin"/>
      <input type="text" v-model="Addresses.address_state" autocomplete="address-level1" placeholder="State" class="address-state"/>
      <input type="text" v-model="Addresses.country" autocomplete="country-name" placeholder="Country" class="address-country"/>
      <div class="save" v-if="addressChanged">
        <button v-on:click="saveAddress()" id="save-address" :disabled="loading">Save</button>
      </div>
    </div>
  </div>
</template>
<script>
// import alpha3 from '../ISO-3166-3.js'
export default {
  props:{
    address:{
        type:Object
    }
  },
  data() {
    return {
      Addresses: {
        name: null,
        number: "",
        line_1: "",
        line_2: "",
        city: "",
        pincode: "",
        address_state: "",
        country: "",
        stateCode:"",
        countryCode:"",
        coordinates: {
          lat: null,
          lng: null
        },
        img: null,
      },
      loading:false,
      resetError:()=>{
                const ele = document.getElementById("save-address")
                console.log("timeout")
                this.loading = false
                ele.innerText = "Save"
                ele.style.backgroundColor = "black"
                ele.style.pointerEvents = "auto"
            }
    };
  },
  mounted(){
    this.Addresses.name = this.address.address_name
    this.Addresses.line_1 = this.address.line_1
    this.Addresses.line_2 = this.address.line_2
    this.Addresses.city = this.address.city
    this.Addresses.pincode = this.address.pincode
    this.Addresses.address_state = this.address.address_state
    this.Addresses.country = this.address.country
    this.Addresses.address_img = this.address.address_img
    this.Addresses.state_code = this.address.state_code
    this.Addresses.country_code = this.address.country_code
    this.Addresses.coordinates.lat = this.address.coordinates?.lat
    this.Addresses.coordinates.lng = this.address.coordinates?.lng

  },
  computed:{
    addressChanged(){
      const Address = JSON.parse(JSON.stringify(this.address))
      var isNotSame = (this.Addresses.name != Address.address_name || this.Addresses.line_1 != Address.line_1 || this.Addresses.line_2 != Address.line_2 || this.Addresses.city != Address.city || this.Addresses.pincode != Address.pincode || this.Addresses.address_state != Address.address_state || this.Addresses.country != Address.country)
      console.log(this.Addresses.name,Address.address_name,this.Addresses.name != Address.address_name , this.Addresses.line_1 != Address.line_1 , this.Addresses.line_2 != Address.line_2 , this.Addresses.city != Address.city , this.Addresses.pincode != Address.pincode , this.Addresses.address_state != Address.address_state , this.Addresses.country != Address.country)
      if(isNotSame && this.Addresses.name != null) this.$store.state.$billing_address.address_name = ""
      else if(this.Addresses.name != null) this.$store.state.$billing_address.address_name = this.Addresses.name
      console.log("Address Changed: ",isNotSame)
      return isNotSame
    }
  },
  methods:{
    setBackground(image){
      return {
        background:`linear-gradient(0deg, transparent, white), url(${image})`,
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '150%'
        }
    },
    image(){
      var address = JSON.parse(JSON.stringify(this.Addresses));
      // console.log(address)
      delete address.name
      delete address.address_img
      delete address.coordinates
      Object.values(address).map(val=>{
          if(val)
              return val.replace(" ", "+")
      })
      // console.log(Object.values(address))
      const query = Object.values(address).join("+")
      this.$maps.get(`/geocode?q=${query}&show=countryInfo,streetInfo`)
      .then(data=>{
        if(!data.data.items.length){
          const timeout =() => {return setTimeout(this.resetError, 2000)}
          // clearTimeout(timeout)
          const ele = document.getElementById("save-address")
          ele.innerText = "Invalid Address!"
          ele.style.backgroundColor = "brown"
          timeout.call()
          return
        }
        console.log(data.data.items[0])
        const { stateCode } = data.data.items[0].address
        const countryCode = data.data.items[0].countryInfo.alpha2
        console.log("Codes from here maps",stateCode,countryCode)
        // console.log("Codes from ISO-3166-3",stateCode, alpha3[countryCode])
        this.Addresses.address_img = `https://image.maps.ls.hereapi.com/mia/1.6/tiltmap?apiKey=pfxL2scJPj4NHy1YtUX4Al_KKinnp3UR9L46edegxlc&ta=60&w=800&h=300&z=16&t=5&c=${data.data.items[0].position.lat},${data.data.items[0].position.lng}&nodot&f=0&nocp=`
        this.Addresses.state_code = stateCode
        // this.Addresses.country_code = alpha3[countryCode]
        this.Addresses.country_code = countryCode

        this.address.address_name = this.Addresses.name
        this.address.line_1 = this.Addresses.line_1
        this.address.line_2 = this.Addresses.line_2
        this.address.city = this.Addresses.city
        this.address.pincode = this.Addresses.pincode
        this.address.address_state = this.Addresses.address_state
        this.address.country = this.Addresses.country
        this.address.address_img = this.Addresses.address_img
        this.address.state_code = this.Addresses.state_code
        this.address.country_code = this.Addresses.country_code
        this.address.coordinates = data.data.items[0].position
        this.loading = false
      }).catch(err=>{
        console.log(err)
      })
    },
    saveAddress(){
      Object.keys(this.address).forEach((i) => this.address[i] = null);
      const ele = document.getElementById("save-address")
      ele.innerText = "Loading..."
      this.loading = true
      this.image()
    }
  }
};
</script>
<style scoped>
.address-card input {
  height: 100%;
  padding: 0px 5px;
  border: none;
  font-size: 1.2em;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  width:100%;
}
.address-card input:placeholder-shown {
  border-radius: 2px;
  background-color: rgb(235, 235, 235);
}

.address-card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  height: 100%;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  color: black;
}
.address-content {
  /* margin:10px; */
  height: 90%;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  grid-template-areas:
    "name name"
    "line1 line1"
    "line2 line2"
    "state pin"
    "city pin"
    "save country";
}
.address-name {
  grid-area: name;
  letter-spacing: 3px;
  text-align: left;
  font-weight:bold;
}
.address-line-1 {
  grid-area: line1;
  font-weight: lighter;
}
.address-line-2 {
  grid-area: line2;
  font-weight: lighter;
  width: 90%;
}
.address-city {
  grid-area: city;
  font-weight: lighter;
}
.address-pin {
  grid-area: pin;
  font-weight: lighter;
}
.address-state {
  grid-area: state;
  font-weight: lighter;
}
.address-country {
  grid-area: country;
  justify-content: flex-end;
  text-align: right;
  font-weight:bold;
}
.save{
  grid-area: save;
  height:100%;
}
.save button{
  margin:0;
  padding:0;
  height:100%;
}
</style>
