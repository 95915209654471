<template>
  <div class="confirm-shipping-container">
    <transition name="page" appear mode="out-in">
    <div class="shipping-options-container" v-if="shippingMethods">
        <div class="shipping-provider-option" v-for="provider,providerName,index in shippingMethods" :key="index" :data-provider="providerName">
            <template v-if="provider.length">
                <div class="shipping-provider-image"><img :src="getImage(providerName)" :data-provider="providerName" alt=""></div>
                <div class="shipping-methods-container">
                    <ShippingMethod v-for="method,index in provider" :key="'m'+index" id="method" v-on:click="selectMethod($event)" :method="method"></ShippingMethod>
                </div>
            </template>
        </div>
    </div>
    <div class="shipping-options-container empty" v-else>
        <h1>{{status}}</h1>
        <h3 v-if="status2">{{status2}}</h3>
    </div>
    </transition>
  </div>
</template>

<script>
import { getShippingMethods } from '../api'
import ShippingMethod from "./ShippingMethod.vue"
const ups_img = require('../assets/ups-light.svg')
const fedex_img = require('../assets/fedex-light.png')
export default {
    components:{
        ShippingMethod
    },
    data(){
        return{
            shippingMethods: null,
            status:"Loading...",
            status2:null
        }
    },
    mounted(){
        this.$store.commit('setShippingMethod',null)
        const cart = JSON.parse(JSON.stringify(this.$store.state.$cart))
        //remove img
        cart.forEach(item => {
            console.log(item.product)
            delete item.product.img
        })
        const shippingDetails = {
            shipping_address:this.$store.state.$shipping_address,
            customer_info: this.$store.state.$customer_info,
            cart
        }
        getShippingMethods(shippingDetails).then((data)=>{
            console.log(data.data)
            this.shippingMethods = data.data
            if(Object.values(this.shippingMethods).every(val=>{return val.length === 0})){
                this.shippingMethods = null
                this.status = "No Shipping Providers Available."
                this.status2 = "Please try again later."
            }
        }).catch((err)=>{
            console.log("Shipping Error")
            this.status = "Error"
        })
    },
    methods:{
        getImage(provider){
            console.log(provider)
            switch(provider){
                case 'FEDEX':
                    console.log(fedex_img)
                    return fedex_img.default
                case 'UPS':
                    return ups_img.default
                default:
                    return null
            }
        },
        selectMethod(event){
            const method = event.target.closest('#method')
            method.dataset.selected = true
            //remove selected from other methods in document
            const methods = document.querySelectorAll('#method')
            methods.forEach(m => {
                if(m !== method){
                    m.dataset.selected = false
                }
            })
            const provider = method.closest('.shipping-provider-option').dataset.provider
            const methodData = this.shippingMethods[provider].find(m => m.service === method.querySelector('h2').innerText)
            //set selected shipping method in store
            this.$store.commit('setShippingMethod',methodData)

            // this.$store.commit('setShipping',methodData)
            console.log(methodData)
            
        }
    }
}
</script>

<style>
.confirm-shipping-container{
    width:100%;
    height:calc(100% - 70px);
    background-color: rgba(0, 0, 0, 0.26);
}
.shipping-options-container{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
}

.empty{
    color:white;
    background-color: rgba(0, 0, 0, 0.161);
    backdrop-filter: blur(0px);
    animation: blur-in 250ms ease-in 500ms forwards;
}

.shipping-provider-option{
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.438);
    width:50%;
    border-radius: 5px;

}
.shipping-methods-container{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
    padding:15px;
}
.shipping-methods{
    transition: all 0.5s ease-in-out;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
    "h2 h2 h2 h4"
    "h3  h3 . h4";
    justify-items: left;
    align-items: center;
    width:90%;
    height:75px;
    background-color: rgb(0, 0, 0);
    padding:5px;
    color:white;
    border-radius:5px;
    gap:2px;
}
.shipping-methods:hover{
    cursor:pointer;
}
.shipping-methods > * {
    pointer-events: none;
    user-select: none;
}
.shipping-provider-image{
    width:100%;
    height:75px;
    display:flex;
    align-items: center;
}
.shipping-provider-option img{
    object-fit: contain;
    width: auto;
    height: 50%;
    align-self: left;
    padding-left:10px;
}
.shipping-provider-option[data-provider="FEDEX"]{
    background-color: #4e148c;
    background: linear-gradient(18.83deg, #5c39a3 37.21%, #4e148c81 100%);
}
.shipping-provider-option[data-provider="UPS"]{
    background: linear-gradient(18.83deg, #7e5844 37.21%, #69422d7b 100%)
}
.shipping-provider-option[data-provider="FEDEX"] .shipping-methods{
    background-color: rgb(231, 231, 231);
    color:black;
    /* background-color: #ff6200;
    color:white; */
}
.shipping-provider-option[data-provider="UPS"] .shipping-methods{
    background-color: #ffc400;
    color:black;
}
.shipping-provider-option img[data-provider="UPS"]{
    height:80%;
}
.shipping-methods h2{
    grid-area: h2;
    font-weight:400;
    margin:0;
    height:min-content;
    font-size: 1.1rem;
    align-self: end;
}
.shipping-methods h3{
    grid-area: h3;
    font-weight:600;
    margin:0;
    height:min-content;
    font-size: 0.8rem;
    opacity:0.7;
    font-style:italic;
    /* align-self: start; */
}
.shipping-methods h4{
    grid-area: h4;
    font-weight:900;
    margin:0;
    height:min-content;
    font-size: 0.9rem;
}
.shipping-methods[data-selected="true"]{
    /* success green background */
    background-color: #047704!important;
    color: white !important;
}
.shipping-methods[data-selected="false"]{
    opacity:0.3;
}
</style>