<template>
<transition name="page" appear>
<div v-if="!$store.state.$cart.length" class="loading">
    <h1>LOADING....</h1>
</div>
<div v-else class="container">
    <div class="container-1">
        <Header></Header>
        <OrderDetails></OrderDetails>
    </div>
    <div class="container-2">
        <ConfirmShipping></ConfirmShipping>
        <div class="continue-button">
            <button :disabled="!$store.state.$selectedShippingMethod || !checkBillingAddress" v-on:click="$router.push('/confirm')">Continue to Payment >>></button>
        </div>
    </div>
</div>
</transition>
</template>

<script>
import OrderDetails from '../components/OrderDetails.vue'
import ConfirmShipping from '../components/ConfirmShipping.vue'
import Header from '../components/Header.vue'
export default {
  components: {Header, OrderDetails, ConfirmShipping},
  computed: {
    checkBillingAddress(){
      if(this.$store.state.$isBillingSame){
        return true
      }
      else{
        const Address = this.$store.state.$billing_address
        var arr = [Address.address_name,Address.line_1,Address.city,Address.pincode,Address.address_state, Address.country]
        console.log(arr)
        console.log(arr.includes(null) || arr.includes(undefined) || arr.includes(""))
        return !(arr.includes(null) || arr.includes(undefined) || arr.includes(""))
      }
    }
  }
}
</script>

<style scoped>
.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    /* background-color: aqua; */
}
.container-2{
    width: 60%;
    height: 100%;
    gap: 0px;
}
.continue-button{
    width: 100%;
    height: 70px;
    flex-grow:1;
}
.continue-button button{
    border-radius:0px;
    height:100%;
    font-size:1.6rem;
    background-color: black;
    text-align: right;
}
.continue-button button:disabled{
    color:rgba(255, 255, 255, 0.58);
    opacity: 0.1;
}

</style>
<style>
.loading{
  display:flex;
  width:100vw;
  height:100vh;
  backdrop-filter: blur(100px);
}
.loading h1{
  margin:auto;
}
.container-1{
  position: relative;
  width:40%;
  min-width:330px;
  height:100%;
  background-color: rgba(0, 0, 0, 0.161);
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(0px);
  animation: blur-in 250ms ease-in 500ms forwards; /* Important */
}
.cost-info{
  display:flex;
  width:100%;
  height:70px;
  color:white;
  background-color: black;
}
.cost-info .sub-total,.cost-info .total{
  width:100%;
  height:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:0 20px;
}
</style>