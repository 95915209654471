<template>
<div class="Status">
    <div class="container">
      <div id="animation"></div>
      <div>
        <h1 class="order-id">#{{orderID}}</h1>
        <h2 id="message">Processing...</h2>
        <!-- Arrives by {{new Date($store.state.$selectedShippingMethod.delivery).toLocaleString()}} {{$store.state.$selectedShippingMethod.deliveryDay}} -->
      </div>
      <transition appear name="fade">
      <div class="order-actions" v-if="show">
        <h4>
          You should be receiving an email with the order details shortly. 
        </h4>
        <button @click="openReceipt">
          <span style="text-decoration:none;color:black;">
            Print Receipt
          </span>
        </button>
      <div>
        <a style="color:white;" href="https://cucircuits.com"><h4 style="text-align:right;width:100%;">Home - \</h4></a>
        <a style="color:white;" href="https://cubeitdone.com"><h4 style="text-align:right;width:100%;">cubeitdone.com - \</h4></a>
      </div>
      </div>
      </transition>
      <div class="order-footer">
        <h5>Have a query? or need support? Please visit our <a href="https://cucircuits.com/support">support</a> page or get in touch with us at <a href="mailto:queries@cucircuits.com">queries@cucircuits.com</a></h5>
      </div>
        
    </div>

</div>
</template>

<script>
import anim from '../assets/animation.json'
export default {
data(){
    return{
        stripe:null,
        animation:null,
        orderID:this.$store.state.$order_id,
        show:false
    }
},
mounted(){
    this.loadLottie()
    sessionStorage.clear()
    if(window?.Stripe) {
      this.stripe = window.Stripe("pk_test_51IHSceEayJCBMwtQ6Mm2Bux7iT6EMmVSixTODGw9d10EK3NS7Xp8JQnbeeMC3nD8BoMJmLMCVUue6vVDyMQbaPxE00clqwW7uJ")
      console.log("Stripe is loaded")
      this.checkStripe()
    } else {
      console.log("Stripe is not loaded")
    }
    
},
methods:{
    loadLottie(){
        console.log("Animation window:",window)
        const animation = lottie.loadAnimation({
            container: document.getElementById('animation'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: anim, // the path to the animation json
            isFrame: true
          });
        //   animation.onSegmentStart = this.complete
          animation.playSegments([0, 70], true);
        console.log(animation)
        this.animation = animation
    },
    checkStripe(){
      const clientSecret = new URLSearchParams(window.location.search).get(
          'payment_intent_client_secret'
      );
      this.stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
        const message = document.querySelector('#message')
        console.log(paymentIntent)

        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
          //   this.animation.onLoopComplete = this.complete
          console.log(this.animation)
            this.animation.onSegmentStart = () =>{
                  this.animation.wrapper.classList.add('success')
                  message.innerText = 'Success! Payment received.';
            }
            this.animation.addEventListener('complete', () => {
              console.log("end")
              const callback = () => {
                  this.show=true
              }
              window?.requestAnimationFrame(callback)
            })
              this.animation.playSegments([70, 127]);
              this.animation.loop = false
              console.log(this.animation)

            break;

          case 'processing':
            message.innerText = "Payment processing. We'll send you an update when payment is received.";
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
              this.animation.onSegmentStart = () =>{
                  this.animation.wrapper.classList.add('error')
                  message.innerText = 'Payment failed. Please try another payment method.';
              }
              this.animation.playSegments([128, 180]);
              this.animation.loop = false
            break;

          default:
              this.animation.onSegmentStart = () =>{
                  this.animation.wrapper.classList.add('error')
                  message.innerText = 'Something went wrong.';
              }
            this.animation.playSegments([128, 180]);
            this.animation.loop = false
            break;
        }
      });
    },
    getRecieptURL(){
        return `${process.env.VUE_APP_RECIEPT_MAKER_SERVICE}/receipt?customerId=${this.$store.state.$customer_id}&orderId=${this.$store.state.$order_id}`
    },
    openReceipt() {
      window.open(this.getRecieptURL(), '_blank');
    }
}
}
</script>
<style scoped>
.container{
    width:90%;
    height:90%;
    background-color: rgba(66, 70, 68, 0.316);
    margin: auto auto;
    border-radius:5px;
    animation: blur-in 250ms ease-in 500ms forwards;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
h1{
  color: rgb(230, 227, 227);
}
h2{
    color:white;
}
.order-actions{

  display:grid;
  height:100px;
  width:90%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  margin:auto;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}
.order-actions h4{
  text-align: left;
  color:white;
  height:auto;
  margin:0;
  width:50%;
}
.order-actions button{
  width:65%;
  height:65%;
  background-color: #d9d9d9;
  color:black;
  font-family: inherit;
  border-radius:8px;
}
.order-footer{
  color:black;
  margin-top:auto;
}
.order-footer a{
  color:inherit;
}
.order-footer h5{
  font-size:1em;
  font-weight:500;
}
.fade-enter-active,
.fade-leave-active {
  transition: max-height 1s ease;
  max-height:300px;
  overflow: hidden;
}

.fade-enter-from,
.fade-leave-to {
  max-height:0px;
  margin:0px;
}
</style>
<style>
.Status{
    height:100vh;
    width:100vw;
    display: flex;
}
#animation{
    height:250px;
    min-width:250px;
    max-width: 550px;
    width:50%;
    margin:0 auto;
    margin-top:auto;
    
}
#animation svg *{
    transition: fill 1s ease-in-out;
    fill:rgb(0, 0, 0)!important;
}
#animation.success svg *{
    fill:green!important;
}
#animation.error svg *{
    fill:brown!important;
}
</style>