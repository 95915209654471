import HomePage from "../../pages/Home.vue";

export const createHomeRoutes = () => {
  return {
    name: "HOME",
    path: "/",
    component: HomePage,
    beforeEnter: (to, from, next) => {
      //if query a doesnot exist, change to to /notfound
      // console.log(to,from,to.path == from.path)
      if (!to.query.a || !to.query.c) {
        next({ name: "NOT FOUND" });
        return
      }
      next();
    }
  };
};
