<template>
<div>
    <div>This is an about page.</div>
  Fetched data:  {{dat}}
    <div>msg :{{ msg }}</div>
    <br>
    <div>store :{{$store}}</div>
    <br>
    <br>
    <div>If $discounts is 1 server hydration is working</div>
    <div>store :{{$store?.state}}</div>
    <br>
    <div>Keycloak init happens only on client; empty if init fails</div>
    <div>keyclaok : {{$keycloak}}</div>
</div>

</template>

<script>
import { useQuery } from "../composables/query.js";
import { getExampleData } from "../api/index.js";

// import { useStore } from 'vuex'

export default {
  name: "AboutPage",
  // asyncData(store) {
  //   return store.dispatch("fetchPost")
  // },
  mounted() {
    console.log(this)
    console.log(this.msg)
    console.log(this.$store)
    console.log(window)
    const { data } = useQuery("example", async () => {
    const { data: result } = await getExampleData(this.$keycloak);
      return result;
    });
    this.dat = data 
    // this.$store.dispatch("fetchPost")
  },
  data() {
    return {
      msg: "Hello",
      dat :null
    }
  },
  computed: {
    // post() {
    //   return this.$store.state.post
    // }},
  }

  // setup() {
  //   const { data } = useQuery("example", async () => {
  //     const { data: result } = await getExampleData();
  //     return result;
  //   });
  //   return { data };
  // },
};
</script>

