const payments = require("./payments");
const paymentsProfile = require("./payments-profile");
const maps = require("./maps");
const reciept = require("./reciept")

module.exports = {
    install(Vue){
        // console.log(Vue)
        Vue.config.globalProperties.$payments = payments;
        Vue.config.globalProperties.$paymentsProfile = paymentsProfile;
        Vue.config.globalProperties.$maps = maps;
        Vue.config.globalProperties.$reciept = reciept;

    }
}