<template>
<div class="shipping-methods" v-if="method">
    <h2>{{method.service}}</h2>
    <h3>Arrives on {{ method.delivery ? new Date(method.delivery).toLocaleString() : "N/A"}} {{method.deliveryDay}}</h3>
    <h4>+ {{$formatCurrency(method.cost,$store.state.$order_info.currency,$store.state.$order_info.exchange_rate)}}</h4>
</div>
</template>
<script>
export default {
    props:{
        method:{
            type: Object,
            required: true
        }
    }
}
</script>