<template>
<div class="payments-container">
    <div class="payment-pills">
        <button class="select-method" v-for="pill,index in pills" :key="index" @click="component = pill" :data-selected="component.title == pill.title" :disabled="disable">
          {{pill.title}}
        </button>
    </div>
    <div class="pill-content" v-if="component && pills">
        <transition name="page" mode="out-in">
        <keep-alive>
            <component :is="component.component" v-bind="component.props" />
        </keep-alive>
        </transition>
    </div>
</div>
</template>
<script>
import PaymentForm from './PaymentForm.vue'
import SavedPayments from './SavedPayments.vue'
export default {
    components:{PaymentForm,SavedPayments},
    props: {
        amount: {
          type: String,
          required: true
        },
        currency:{
          type:String,
          required:true
        },
        additionalData:{
          type:Object,
          required:true
        }
    },
    data(){
        return{
            stripe:null,
            disable:true,
            selectedCard:null,
            cardList:null,
            pills:[
                    {
                        title:'Saved Cards',
                        component:'SavedPayments',
                        props:{
                            cardList:this.cardList,
                            secret:null,
                            pi_id:null,
                            amount:this.amount,
                            currency:this.currency,
                            stripe:null,
                            additionalData:this.additionalData 
                        }
                    },
                    {
                        title:'New Card',
                        component:'PaymentForm',
                        props:{
                            secret:null,
                            amount:null,
                            currency:null,
                            stripe:null,
                            additionalData:this.additionalData
                        }
                    }
                ],
            component:null
        }
    },
    created(){
        this.component  = this.pills[0]
    },
    mounted(){
        if(window?.Stripe) {
          this.stripe = window.Stripe("pk_test_51IHSceEayJCBMwtQ6Mm2Bux7iT6EMmVSixTODGw9d10EK3NS7Xp8JQnbeeMC3nD8BoMJmLMCVUue6vVDyMQbaPxE00clqwW7uJ")
          console.log("Stripe is loaded")
          this.initialize();
        } else {
          console.log("Stripe is not loaded")
        }
    },
    methods:{
        async initialize() {
          this.setLoading(true);
            const p1 = this.$payments.post("/payments/payment-intent",{
              amount: this.amount,
              currency: this.currency,
              additionalData: this.additionalData
            });
            const p2 = this.getCards()
            
            Promise.allSettled([p1,p2]).then((results)=>{
                console.log(results)
                if(![results[0].status,results[1].status].every(status => {return status === "fulfilled"})) return
                const { client_secret:clientSecret, amount, currency, id} = results[0].value.data;
                console.log(clientSecret, amount, currency, id)
                this.pills[0].props.secret = clientSecret;
                this.pills[0].props.pi_id = id;
                this.pills[1].props.secret = clientSecret;
                this.pills[0].props.stripe = this.stripe;
                this.pills[1].props.stripe = this.stripe;
                this.pills[1].props.amount = amount;
                this.pills[1].props.currency = currency;
                switch(results[1].status){
                    case "fulfilled":
                        this.cardList = results[1].value.data.data
                        console.log("Payments",this.cardList)
                        this.pills[0].props.cardList = this.cardList
                        this.setLoading(false)
                        this.updatePayText("Pay "+this.$formatPay(amount,currency))
                        break;
                    case "rejected":
                        
                        break;
                }
            })
            // const response = await 
            // console.log(response)
            // const { client_secret:clientSecret, amount, currency } = response.data;
            // console.log(clientSecret, amount, currency)
            // this.updatePayText("Pay "+this.$formatPay(amount,currency))
        },
        getCards(){
            return this.$paymentsProfile.get('/cards')
            // .then((data)=>{
            //     this.cardList = data.data.data.concat(data.data.data.concat(data.data.data.concat(data.data.data)))
            //     console.log("Payments",this.cardList)
            // }).catch((error)=>{
            //     console.log(error)
            // })
        },
        setLoading(isLoading) {
            if (isLoading) {
              this.disable = true;
              // Disable the button and show a spinner
              document.querySelector("#spinner").classList.remove("hidden");
              document.querySelector("#button-text").classList.add("hidden");
            } else {
              this.disable = false;
              document.querySelector("#spinner").classList.add("hidden");
              document.querySelector("#button-text").classList.remove("hidden");
            }
        },
        updatePayText(text) {
            document.querySelector("#button-text").innerHTML = text;
        }
    }

}
</script>

<style>
.payments-container{
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.63);
  width: 30vw;
  min-width: 450px;
  max-width:700px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.payment-pills{
    display:flex;
    gap:10px;
}
.pill-content{
    display:flex;
    justify-content: center;
    align-items: center;
    padding-top:40px;
    height:450px;
}

#payment-form {
  background-color: rgba(35, 33, 33, 0.217);
  width: 100%;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  /* height:385px; */
}

.saved-payments{
  display:flex;
  flex-direction: column;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #000000;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #000000;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

</style>