<template>
    <div class="item">
      <div class="item-image">
          <img :src="item.product.img" alt="">
      </div>
      <div class="item-name">
        <h4>{{item.product.name}}</h4>
        <h5><em>SKU:{{item.product.sku}}</em></h5>
        
      </div>
      <div class="item-quantity">
        <h5>{{item.quantity}}</h5>
      </div>
      <div class="item-price">
        <h4>{{$formatCurrency(item.price,$store.state.$order_info.currency,$store.state.$order_info.exchange_rate)}}</h4>
        <h5>{{$formatCurrency(item.unit_price,$store.state.$order_info.currency,$store.state.$order_info.exchange_rate)}}</h5>
      </div>
    </div>
</template>

<script>
export default {
props:{
  item:{
    type:Object,
    required:true
  }
}
}
</script>

<style>
.item{
    width:90%;
    display:grid;
    grid-template-columns: 1fr 2.5fr 0.5fr 1fr;
    grid-auto-rows: 100%;
    grid-template-areas: "img name quantity price";
    border-radius:5px;
    background-color: rgba(255, 255, 255, 0.81);
    align-content: center;
    user-select:none;
}
.item-image{
    grid-area:img;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.item img{
    height: 60%;
    margin: 2% auto 2% auto;
    border-radius: 5px;
    box-shadow: 0px 0px 60px -30px;
    aspect-ratio: 1/1;
    width: 60%;
    object-fit: cover;
    object-position: center;
    /* outline: 2px solid black; */
    background-color: hsl(198, 5%, 50%);
}
.item-name{
    grid-area:name;
    display:flex;
    flex-direction:column;
    justify-content: center; 
    align-items: flex-start;
    gap:1px;
}

.item-name h4,.item-name h5{
    margin: 0;
    /* padding: 15px 0px 10px 0px; */
    font-size: 1rem;
    font-weight:400;
    text-align: left;
    color: black;
}

.item-name h4{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight:500;
}

.item-quantity{
    grid-area:quantity;
    display:flex;
    justify-content: center;
    align-items: center;
}
.item-price{
    grid-area:price;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    gap:5px;
}

.item-price h4,.item-price h5{
    margin:0;
}
.item-price h5,.item-name h5{
    opacity: 0.4;
}

</style>