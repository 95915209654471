import { createStore } from 'vuex'

export default function () {
  return createStore({
    // IMPORTANT: state must be a function so the module can be
    // instantiated multiple times
    state: () => ({
      $analyzed:null,
      $created_at:null,
      $order_id:null,
      $customer_id:null,
      $isBillingSame:true,
      $cart : {},
      $shipping_address : {},
      $billing_address:{},
      $shipping : null,
      $discounts : null,
      $customer_info : null,
      $order_info:{},
      $selectedShippingMethod : null,
      $cart_total:null,
      $cart_sub_total:null,
      $ttl:null
    }),
    mutations:{
      setShippingMethod(state, payload){
        state.$selectedShippingMethod = payload
      },
      setBillingAddress(state, payload){
        console.log(payload)
        state.$billing_address = {...payload}
      }
    }
})
}