import StatusPage from "../../pages/Status.vue";

export const createStatusRoutes = () => {
  return {
    name: "Status",
    path: "/status",
    component: StatusPage,
    // beforeEnter: (to, from, next) => {
    //   //if query a doesnot exist, change to to /notfound
    //   if (!to.query.a || !to.query.c) {
    //     next({ name: "NOT FOUND" });
    //     return
    //   }
    //   next();
    // }
  };
};