import { createRouter } from "vue-router";
import { createHomeRoutes } from "./routes/home.js";
import { createAboutRoutes } from "./routes/about.js";
import { createConfirmRoutes } from "./routes/confirm.js";
import { createCatchAllRoutes } from "./routes/catchAll.js";
import { createStatusRoutes } from "./routes/status.js";

export const createAppRouter = ({ history }) => {
  const routes = [createHomeRoutes(), createAboutRoutes(),createConfirmRoutes(),createStatusRoutes(),createCatchAllRoutes()];

  return createRouter({
    history,
    routes,
  });
};
